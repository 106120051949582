

export const helpTitle = 'EDR Admin';
export const helpMessage = 'Api throws error. Please contact development team.';
export const notFoundMessage = 'Requested Api not available.';

export function parseJwt(token) {
    if (token && token.length > 0) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } else {
        return null;
    }
}
export class ApiReturn {
    status: boolean;
    message: string;
    DetailedMessage?: string;
    StatusCode?: StatusCode;
    data?: any;
    token?: string;
    UserToken?: string;
    unRead?: number;
    total?: number;
}

export enum colorHelper {
    basic = 'basic',
    primary = 'primary',
    success = 'success',
    info = 'info',
    warning = 'warning',
    danger = 'danger',
    control = 'control',
}

export enum StatusCode {
    OK = 'OK', NotFound = 'NotFound', InternalServerError = 'InternalServerError', InvalidLoginError = 'InvalidLoginError',
}
export enum RestMethod {
    get, post, put, update, delete,
}

export enum ContentType {
    json = 'application/json',
    formData = 'multipart/form-data;',
}
export class EdrRestApiJson {
    url: string;
    restMethod: RestMethod;
    authentication: boolean;
    contentType?: ContentType;
    formData?: FormData;
    queryParams?: {
        type?: string;
        limit_str?: string;
        limit_end?: string;
        start_date?: string;
        end_date?: string;
    };
    pathParams?: string;
    password?: string;
    email?: string;
    name?: string;
    mobile?: string;
    cuisines?: string;
    opening_time?: string;
    closing_time?: string;
    address?: {
        longitude?: string;
        latitude?: string;
        house?: string;
        locality?: string;
        area?: string;
        city?: string;
        pincode?: string;
    };
    restaurant_id?: string;
    max?: string;
    off?: string;
    validity?: string;
    is_flat?: string;
    cost?: string;
    category?: string;
    vegetarian?: string;
    restaurant_details?: {
        restaurant_id?: string,
        dish_status?: [],
    }[];
    isflat?: string;
    deleted?: string;
    resetpassword?: string;
    oldPassword?: string;
    newPassword?: string;
    categoryId?: string;
    description?: string;
    item_id?: string;
    _id?: string;
    shopTimings?: {};
    firebasedeviceId?: string;
    deviceType?: string;
}
export class MessageData {
    public LoanNo?: string;
    public UserData?: any;
    public Token?: string;
    public UserToken?: string;
    public UserName?: string;
    public Role?: string;

}
export const postLoginJson: EdrRestApiJson = {
    url: 'v1/admin/login',
    restMethod: RestMethod.post,
    authentication: false,
    email: '',
    password: '',
};
export const postResetJson: EdrRestApiJson = {
    url: 'v1/admin/reset',
    restMethod: RestMethod.post,
    authentication: false,
    email: '',
};
export const postResetLoginJson: EdrRestApiJson = {
    url: 'v1/admin/reset/login',
    restMethod: RestMethod.post,
    authentication: false,
    email: '',
    resetpassword: '',
    password: '',
};

export const updatePasswordJson: EdrRestApiJson = {
    url: 'v1/admin/update/password',
    restMethod: RestMethod.put,
    authentication: true,
    password: '',
};

export const getRevenueJson: EdrRestApiJson = {
    url: 'v1/admin/revenue',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getAveragePreparationTimeJson: EdrRestApiJson = {
    url: 'v1/admin/preparation-time',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getRejectionRateJson: EdrRestApiJson = {
    url: 'v1/admin/rejection-rate',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getShopsSalesJson: EdrRestApiJson = {
    url: 'v1/admin/shops-sales',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getShopsReportJson: EdrRestApiJson = {
    url: 'v1/admin/shops-reports',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getOrderJson: EdrRestApiJson = {
    url: 'v1/admin/orders',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getOrderStatusJson: EdrRestApiJson = {
    url: 'v1/admin/order-status',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getOrdersRejectJson: EdrRestApiJson = {
    url: 'v1/admin/orders/reject',
    queryParams: {
        type: 'all',
        limit_str: '',
        limit_end: '',
    },
    restMethod: RestMethod.get,
    authentication: true,
};

export const getOrdersFailedJson: EdrRestApiJson = {
    url: 'v1/admin/orders/failed',
    queryParams: {
        type: 'all',
        limit_str: '',
        limit_end: '',
    },
    restMethod: RestMethod.get,
    authentication: true,
};

export const getOrderDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/order',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getShopJson: EdrRestApiJson = {
    url: 'v1/admin/all/shop',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getShopRatingJson: EdrRestApiJson = {
    url: 'v1/admin/see-all-reviews',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getShopDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/shop',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getShopTimeDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/shopTime',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const getShopManagerJson: EdrRestApiJson = {
    url: 'v2/admin/shop',
    restMethod: RestMethod.put,
    authentication: true,
};

export const deleteShopManagerJson: EdrRestApiJson = {
    url: 'v1/admin/shop',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};
export const deleteShopJson: EdrRestApiJson = {
    url: 'v2/admin/remove/shop',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export const deleteShopTimeJson: EdrRestApiJson = {
    url: 'v1/admin/shopTime',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export const getManagerJson: EdrRestApiJson = {
    url: 'v1/admin/all/manager',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getUnassignedManagerJson: EdrRestApiJson = {
    url: 'v1/admin/manager/un-assigned',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getManagerDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/manager',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const postManagerJson: EdrRestApiJson = {
    url: 'v1/admin/manager/new',
    restMethod: RestMethod.post,
    authentication: true,
    name: '',
    email: '',
    mobile: '',
};

export const deleteManagerJson: EdrRestApiJson = {
    url: 'v1/admin/manager',
    restMethod: RestMethod.delete,
    authentication: true,
    // pathParams: ''
};

export const getOfferJson: EdrRestApiJson = {
    url: 'v1/admin/offers',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getOfferDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/offer',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const deleteOfferJson: EdrRestApiJson = {
    url: 'v1/admin/offer',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export const getUserTypeJson: EdrRestApiJson = {
    url: 'v1/admin/users',
    restMethod: RestMethod.get,
    authentication: true,
};




// creating shop
export class postShopJson extends EdrRestApiJson {
    url = 'v2/admin/add/shop';
    restMethod = RestMethod.post;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}
export class updateShopJson extends EdrRestApiJson {
    url = 'v2/admin/update/shop';

    restMethod = RestMethod.put;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
    pathParams = '';
}

export const postShopTimeJson: EdrRestApiJson = {
    url: 'v1/admin/update/shopTime',
    restMethod: RestMethod.post,
    authentication: true,
    pathParams: '',
    shopTimings: {},
};

export const postOfferJson: EdrRestApiJson = {
    url: 'v1/admin/offer/add',
    restMethod: RestMethod.post,
    authentication: true,
    restaurant_id: '',
    max: '',
    off: '',
    validity: '',
    is_flat: '',
};

export const updateOfferJson: EdrRestApiJson = {
    url: 'v1/admin/offer',
    restMethod: RestMethod.put,
    authentication: true,
    pathParams: '',
    off: '',
    is_flat: '',
};

export const getPromocodeJson: EdrRestApiJson = {
    url: 'v1/admin/cost/promocode',
    queryParams: {
        type: 'all',
        limit_str: '',
        limit_end: '',
    },
    restMethod: RestMethod.get,
    authentication: true,
};

export const postCuisinesJson: EdrRestApiJson = {
    url: 'v1/admin/cuisines/create',
    restMethod: RestMethod.post,
    authentication: true,
    name: '',
};

export const updateCuisinesJson: EdrRestApiJson = {
    url: 'v1/admin/cuisines',
    restMethod: RestMethod.put,
    authentication: true,
    pathParams: '',
    name: '',
};

export const getCuisinesJson: EdrRestApiJson = {
    url: 'v1/admin/cuisines/all',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getCuisineDetailJson: EdrRestApiJson = {
    url: 'v1/admin/cuisines',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export const deleteCuisinesJson: EdrRestApiJson = {
    url: 'v1/admin/cuisines',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export const getCategoryJson: EdrRestApiJson = {
    url: 'v1/admin/category/all',
    restMethod: RestMethod.get,
    authentication: true,
};

export const deleteCategoryJson: EdrRestApiJson = {
    url: 'v1/admin/category/delete',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export const getCategoryDetailJson: EdrRestApiJson = {
    url: 'v1/admin/category',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};

export class postCategoryJson extends EdrRestApiJson {
    url = 'v1/admin/category/create';
    restMethod = RestMethod.post;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export class updateCategoryJson extends EdrRestApiJson {
    url = 'v1/admin/category/update';
    restMethod = RestMethod.put;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export const getProfileJson: EdrRestApiJson = {
    url: 'v1/admin/profile',
    restMethod: RestMethod.get,
    authentication: true,
};

export const updateProfileJson: EdrRestApiJson = {
    url: 'v1/admin/profile/update',
    restMethod: RestMethod.put,
    authentication: true,
    name: '',
};

export const getProductJson: EdrRestApiJson = {
    url: 'v1/admin/generic-product/all',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getProductDetailJson: EdrRestApiJson = {
    url: 'v1/admin/generic-product',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: '',
};
export class postProductJson extends EdrRestApiJson {
    url = 'v1/admin/generic-product/create';
    restMethod = RestMethod.post;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export class updateProductJson extends EdrRestApiJson {
    url = 'v1/admin/generic-product/update';
    restMethod = RestMethod.put;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export const deleteProductJson: EdrRestApiJson = {
    url: 'v1/admin/generic-product/delete',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: '',
};

export class postBannerJson extends EdrRestApiJson {
    url = 'v1/admin/banner/upload';
    restMethod = RestMethod.post;
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export class updateBannerJson extends EdrRestApiJson {
    url = 'v1/admin/banner';
    restMethod = RestMethod.put;
    pathParams = '';
    contentType = ContentType.formData;
    formData: FormData = new FormData();
    authentication = true;
}

export const getBannerJson: EdrRestApiJson = {
    url: 'v1/admin/banner',
    restMethod: RestMethod.get,
    authentication: true,
};

export const getBannerDetailsJson: EdrRestApiJson = {
    url: 'v1/admin/banner/show',
    restMethod: RestMethod.get,
    pathParams: '',
    authentication: true,
};

export const deleteBannerJson: EdrRestApiJson = {
    url: 'v1/admin/banner/delete',
    restMethod: RestMethod.delete,
    pathParams: '',
    authentication: true,
};

export const shopListJson: EdrRestApiJson = {
    url: 'v1/admin/all/select-shops',
    restMethod: RestMethod.get,
    pathParams: '',
    authentication: true,
};

export const getSalesByMenuJson: EdrRestApiJson = {
    url: 'v1/admin/sales-menu-items',
    restMethod: RestMethod.get,
    pathParams: '',
    authentication: true,
};

export const getNotificationJson: EdrRestApiJson = {
    url: 'v1/admin/notifications',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const setNotificationsReadJson: EdrRestApiJson = {
    url: "v1/admin/notifications",
    restMethod: RestMethod.post,
    authentication: true,
    _id: '',
};

export function GenTimePullDown(MinuteInterval) {
    let n, i, keyHour, valueHour, keyMinute, hourFormat;
    const timeArray = [{ key: "00:00", value: "12:00 AM" }];
    (n = 0),
        (i = 0),
        (valueHour = 0),
        (keyMinute = 0),
        (keyHour = 0),
        (hourFormat = "AM");
    for (let e = 1; e < (60 / MinuteInterval) * 24; e++) {
        60 == (i += MinuteInterval) &&
            ((keyHour = n += 1), n >= 12 && (hourFormat = "PM"), (i = 0)),
            (valueHour = n > 12 ? n - 12 : n),
            0 == n && (valueHour = 12),
            1 == n.toString().length &&
            ((keyHour = "0" + n.toString()), (keyHour = "0" + n.toString())),
            1 == valueHour.toString().length && (valueHour = "0" + valueHour.toString()),
            (keyMinute = 1 == i.toString().length ? "0" + i.toString() : i),
            timeArray.push({
                key: keyHour.toString() + ":" + keyMinute.toString(),
                value: valueHour.toString() + ":" + keyMinute.toString() + " " + hourFormat,
            });
    }
    timeArray.push({ key: "00:00", value: "12:00 AM" });
    return timeArray;
}

export const PostFbTokenJson: EdrRestApiJson = {
    url: 'v1/admin/post-user-token',
    restMethod: RestMethod.post,
    authentication: true,
    firebasedeviceId: '',
    deviceType: '',

}