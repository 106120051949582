import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { CommunicatorService } from '@services/communicator/communicator.service';
import { MessagebusService } from '@services/messagebus/messagebus.service';
import { ToastService } from '@services/toastservice/toastservice.service';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';
@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService,
    private seoService: SeoService,
    private afMessaging: AngularFireMessaging,
    private toastService: ToastService,
    private messagebusService: MessagebusService,
    private communicatorService: CommunicatorService,
  ) {
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }
}
