import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageData } from '../standard';

@Injectable({
  providedIn: 'root',
})
export class MessagebusService {
  private UserData = new Subject<any>();
  private UserDetails = new Subject<any>();
  private NotificationReceived = new Subject<any>();
  constructor() { }

  setUserData(dataToBus: MessageData) {
    this.UserData.next(dataToBus);
  }

  clearUserData() {
    this.UserData.next();
  }

  getUserData(): Observable<MessageData> {
    return this.UserData.asObservable();
  }


  public refreshUserDetails(dataToBus: MessageData) {
    this.UserDetails.next(dataToBus);
  }

  clearUserDetails() {
    this.UserDetails.next();
  }

  getUserDetails(): Observable<MessageData> {
    return this.UserDetails.asObservable();
  }

  public refreshNotifications(dataToBus?: MessageData) {
    this.NotificationReceived.next(dataToBus);
  }
  clearNotifications() {
    this.NotificationReceived.next();
  }
  getNotifications(): Observable<MessageData> {
    return this.NotificationReceived.asObservable();
  }
}
