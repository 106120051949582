export const environment = {
  production: false,
  environment: 'beta',
  sentry: true,
  baseUrl: "https://betaapi.expresseatanddrink.com/",
  tracesSampleRate: 0.25,
  firebase: {
    apiKey: "AIzaSyBavueOTiwu_9axYGdUPQx8sFQTVebRg1Q",
    authDomain: "edr-user-apps.firebaseapp.com",
    projectId: "edr-user-apps",
    storageBucket: "edr-user-apps.appspot.com",
    messagingSenderId: "824693882806",
    appId: "1:824693882806:web:442256883f90d8a02d0b17",
    measurementId: "G-TQNYZZXNE8"
  }
};
