import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router) { }
  get token() {
    const token = JSON.parse(localStorage.getItem('user'));
    return token;
  }
  logout() {
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
}
