import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbGlobalLogicalPosition, NbIconConfig, NbToastrService } from '@nebular/theme';
import { colorHelper, helpMessage, helpTitle, notFoundMessage } from '../standard';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(private toastrService: NbToastrService,
    private router: Router,) { }
  showConfigError() {
    try {
      this.toastrService.show(helpMessage, helpTitle, {
        preventDuplicates: true,
        destroyByClick: true,
        status: colorHelper.danger,
        // duration: 3000,
        limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END,
      });
    } catch (Ex) {
    }
  }

  showNotFoundInApi() {
    this.toastrService.show(notFoundMessage, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      // duration: 3000,
      limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END,
    });
  }
  showSuccess(message) {
    this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.success,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }
  showError(message) {
    this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }

  showInvalidLogin() {
    this.toastrService.show('Invalid login credentials try again!', helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      // duration: 3000,
      limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END,
    });
  }

  showNotification(message: string) {
    const iconConfig: NbIconConfig = { icon: 'shopping-cart-outline', pack: 'eva' };
    this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.info,
      duration: 0,
      position: NbGlobalLogicalPosition.TOP_END,
      icon: iconConfig
    })
  }
}
