import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
function sentryInit() {
  Sentry.init({
    environment: environment.environment,
    dsn: 'https://282914bbfeae40f387e0d6212e595cb8@o1096075.ingest.sentry.io/6116281',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.tracesSampleRate,
  });
}
if (environment.sentry) {
  sentryInit();
}

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = window.console.warn = window.console.info = function () {
      // Don't log anything.
    };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
